@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn {
		@apply border-0 rounded-2xl font-Amsi mx-auto text-red bg-white text-[25px] sm:text-[30px] leading-[30px] uppercase btnShadow px-4 py-1 sm:px-8 sm:py-3;
	}

	.container {
		@apply max-w-[1400px] mx-auto px-4;
	}
	.sogehts-text {
		@apply text-orange bg-[url('/src/bgimages/sogehtsbg.png')] bg-no-repeat bg-[length:100%_100%] px-8 py-16 max-w-[400px] mx-auto;
	}
	.sogehts-text-inner {
		@apply -rotate-[9deg] font-Amsi text-[30px] leading-[33px];
	}
	.h1 {
		@apply text-white text-[40px] sm:text-[60px] leading-tight font-Amsi uppercase text-center antialiased;
	}
	.h2 {
		@apply text-white text-[24px] sm:text-[50px] leading-tight font-Amsi uppercase text-center mb-2 lg:mb-3 antialiased;
	}
	.h3 {
		@apply text-white text-[23px] sm:text-[30px] leading-tight font-Amsi uppercase text-center antialiased;
	}
	.h4 {
		@apply text-white text-[40px] sm:text-[60px] leading-[44px] sm:leading-[60px] font-Amsi pb-10 uppercase;
	}
	.h5 {
		@apply text-orange text-[30px] sm:text-[45px] leading-[30px] sm:leading-[50px] font-Amsi uppercase;
	}
	.header-item {
		@apply text-white font-Amsi text-[35px] uppercase drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)];
	}

	.formErrorMsg {
		@apply text-sm font-Museo mt-3 text-red bg-white rounded-xl p-2 border-2;
	}

	.card {
		@apply relative w-full h-full rotate-y-0 transition-all duration-1000 cursor-pointer;
	}
	.carFlipped {
		@apply rotate-y-180;
	}
	.cardFace {
		@apply absolute w-full h-full backface-hidden bg-white bg-cover;
	}
	.cardFaceFront img {
		@apply w-full;
	}
	.cardFaceBack {
		@apply rotate-y-180;
	}
	.cardFaceBack img {
		@apply w-full;
	}

	strong {
		@apply font-Amsi text-[19px] lg:text-[25px];
	}
	p {
		@apply text-white;
	}
}

.weekday-3 {
	animation: float2 6s ease-in-out infinite;
	position: absolute;
	left: 11%;
	top: -25%;
	width: 10%;
	z-index: 2;
	transform: rotate(6deg);
	filter: blur(3px);
}
.weekday-2 {
	animation: float 6s ease-in-out infinite;
	position: absolute;
	left: 8%;
	top: -8%;
	width: 15%;
	z-index: 5;
	transform: rotate(-6deg);
	filter: blur(2px);
}
.weekday-1 {
	animation: float2 6s ease-in-out infinite;
	position: absolute;
	left: 6%;
	top: 20%;
	width: 20%;
	z-index: 10;
	transform: rotate(6deg);
	filter: blur(1px);
}
.weekday {
	position: relative;
	z-index: 15;
}
.weekday1 {
	animation: float 6s ease-in-out infinite;
	position: absolute;
	right: 9%;
	top: 0%;
	width: 20%;
	z-index: 10;
	transform: rotate(6deg);
	filter: blur(1px);
}
.weekday2 {
	animation: float2 6s ease-in-out infinite;
	position: absolute;
	right: 10%;
	top: -20%;
	width: 15%;
	z-index: 5;
	transform: rotate(-6deg);
	filter: blur(2px);
}
.weekday3 {
	animation: float 6s ease-in-out infinite;
	position: absolute;
	right: 15%;
	top: -35%;
	width: 10%;
	z-index: 2;
	transform: rotate(6deg);
	filter: blur(3px);
}

@keyframes float {
	0% {
		transform: translatey(0px) translatex(0px) rotate(2deg);
	}
	50% {
		transform: translatey(-20px) translatex(-2px) rotate(6deg);
	}
	100% {
		transform: translatey(0px) translatex(0px) rotate(2deg);
	}
}

@keyframes float2 {
	0% {
		transform: translatey(0px) translatex(0px) rotate(-2deg);
	}
	50% {
		transform: translatey(-20px) translatex(2px) rotate(-6deg);
	}
	100% {
		transform: translatey(0px) translatex(0px) rotate(-2deg);
	}
}

/* simple captcha overwrite */
#reload_href {
	text-decoration: underline;
	line-height: 2rem;
	color: #9e6a0a !important;
}
#canv {
	float: left;
	margin-right: 1rem;
	border-radius: 0.4rem;
}

/* left align date input field */
input::-webkit-date-and-time-value {
	/* ios 16+ fix */
	text-align: left;
}

/* Remove number arrows from input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.teaser-advice .grid {
	display: block !important;
}

.contentGradient {
	background: rgb(254, 199, 0);
	background: linear-gradient(
		180deg,
		rgba(254, 199, 0, 1) 0%,
		rgba(229, 20, 76, 1) 100%
	);
}

.cardShadow {
	filter: drop-shadow(0px 3.86438px 6.00863px rgba(0, 0, 0, 0.25))
		drop-shadow(0px 12.0173px 12.0173px rgba(0, 0, 0, 0.1));
}
.btnShadow {
	box-shadow:
		0px 0px 5px rgba(0, 0, 0, 0.2),
		0px 10px 10px rgba(0, 0, 0, 0.2);
}
.tileShadow {
	box-shadow:
		0px 6.49697px 10.102px rgba(0, 0, 0, 0.25),
		0px 20.204px 20.204px rgba(0, 0, 0, 0.1);
}
.calenderShadow {
	filter: drop-shadow(0px -5.27446px 17.1104px rgba(0, 0, 0, 0.25))
		drop-shadow(0px 1.51905px 13.1218px rgba(0, 0, 0, 0.2));
}
.calenderCopyShadow {
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
}
.headerGradient {
	background: linear-gradient(180deg, #fbc32f 0%, #f5a72a 100%);
}

/** sealife logo darf nicht angeschnitten sein, daher ausblenden**/
.tilehook img[src="images\/providers\/SEALIFE.png"] {
	filter: brightness(0) invert(1);
}

.promosoonGradient {
	background: linear-gradient(180deg, #fbc32f 0%, #f5a72a 100%);
}
