.confettiWrapper {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

[class|="confetti"] {
	position: absolute;
}

.confetti-0 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 68%;
	opacity: 0.8095922299;
	transform: rotate(89.5539867841deg);
	-webkit-animation: drop-0 4.1875877683s 0.6136193959s infinite;
	animation: drop-0 4.1875877683s 0.6136193959s infinite;
}

@-webkit-keyframes drop-0 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-0 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-1 {
	width: 24px;
	height: 1.6px;
	background-color: #00db29;
	top: -10%;
	left: 23%;
	opacity: 1.1847150605;
	transform: rotate(240.856098206deg);
	-webkit-animation: drop-1 4.4334288142s 0.3840334005s infinite;
	animation: drop-1 4.4334288142s 0.3840334005s infinite;
}

@-webkit-keyframes drop-1 {
	100% {
		top: 110%;
		left: 27%;
	}
}

@keyframes drop-1 {
	100% {
		top: 110%;
		left: 27%;
	}
}
.confetti-2 {
	width: 27px;
	height: 2.8px;
	background-color: #00db29;
	top: -10%;
	left: 88%;
	opacity: 1.0380558473;
	transform: rotate(295.4774069109deg);
	-webkit-animation: drop-2 4.9648687868s 0.9555663846s infinite;
	animation: drop-2 4.9648687868s 0.9555663846s infinite;
}

@-webkit-keyframes drop-2 {
	100% {
		top: 110%;
		left: 95%;
	}
}

@keyframes drop-2 {
	100% {
		top: 110%;
		left: 95%;
	}
}
.confetti-3 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 61%;
	opacity: 1.1983450865;
	transform: rotate(11.5021807622deg);
	-webkit-animation: drop-3 4.310064907s 0.928411921s infinite;
	animation: drop-3 4.310064907s 0.928411921s infinite;
}

@-webkit-keyframes drop-3 {
	100% {
		top: 110%;
		left: 72%;
	}
}

@keyframes drop-3 {
	100% {
		top: 110%;
		left: 72%;
	}
}
.confetti-4 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 25%;
	opacity: 0.9995390939;
	transform: rotate(284.6293594951deg);
	-webkit-animation: drop-4 4.3073194004s 0.0431968643s infinite;
	animation: drop-4 4.3073194004s 0.0431968643s infinite;
}

@-webkit-keyframes drop-4 {
	100% {
		top: 110%;
		left: 30%;
	}
}

@keyframes drop-4 {
	100% {
		top: 110%;
		left: 30%;
	}
}
.confetti-5 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 66%;
	opacity: 1.0683193792;
	transform: rotate(68.6597673355deg);
	-webkit-animation: drop-5 4.3600507963s 0.0942079373s infinite;
	animation: drop-5 4.3600507963s 0.0942079373s infinite;
}

@-webkit-keyframes drop-5 {
	100% {
		top: 110%;
		left: 67%;
	}
}

@keyframes drop-5 {
	100% {
		top: 110%;
		left: 67%;
	}
}
.confetti-6 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 44%;
	opacity: 0.5521390591;
	transform: rotate(179.576051649deg);
	-webkit-animation: drop-6 4.5258170312s 0.9267244432s infinite;
	animation: drop-6 4.5258170312s 0.9267244432s infinite;
}

@-webkit-keyframes drop-6 {
	100% {
		top: 110%;
		left: 55%;
	}
}

@keyframes drop-6 {
	100% {
		top: 110%;
		left: 55%;
	}
}
.confetti-7 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 12%;
	opacity: 1.4672666872;
	transform: rotate(134.0518981927deg);
	-webkit-animation: drop-7 4.6441332158s 0.994582417s infinite;
	animation: drop-7 4.6441332158s 0.994582417s infinite;
}

@-webkit-keyframes drop-7 {
	100% {
		top: 110%;
		left: 18%;
	}
}

@keyframes drop-7 {
	100% {
		top: 110%;
		left: 18%;
	}
}
.confetti-8 {
	width: 21px;
	height: 0.4px;
	background-color: #fffc00;
	top: -10%;
	left: 53%;
	opacity: 1.3226369466;
	transform: rotate(25.6580690191deg);
	-webkit-animation: drop-8 4.0965599828s 0.99761724s infinite;
	animation: drop-8 4.0965599828s 0.99761724s infinite;
}

@-webkit-keyframes drop-8 {
	100% {
		top: 110%;
		left: 65%;
	}
}

@keyframes drop-8 {
	100% {
		top: 110%;
		left: 65%;
	}
}
.confetti-9 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 71%;
	opacity: 1.0652602283;
	transform: rotate(86.6592770478deg);
	-webkit-animation: drop-9 4.5042746273s 0.997072609s infinite;
	animation: drop-9 4.5042746273s 0.997072609s infinite;
}

@-webkit-keyframes drop-9 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-9 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-10 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 1%;
	opacity: 1.2225106172;
	transform: rotate(168.7627862503deg);
	-webkit-animation: drop-10 4.8965853627s 0.0538203196s infinite;
	animation: drop-10 4.8965853627s 0.0538203196s infinite;
}

@-webkit-keyframes drop-10 {
	100% {
		top: 110%;
		left: 6%;
	}
}

@keyframes drop-10 {
	100% {
		top: 110%;
		left: 6%;
	}
}
.confetti-11 {
	width: 26px;
	height: 2.4px;
	background-color: #fffc00;
	top: -10%;
	left: 72%;
	opacity: 0.8966044672;
	transform: rotate(200.5431655388deg);
	-webkit-animation: drop-11 4.5499145276s 0.1657785364s infinite;
	animation: drop-11 4.5499145276s 0.1657785364s infinite;
}

@-webkit-keyframes drop-11 {
	100% {
		top: 110%;
		left: 86%;
	}
}

@keyframes drop-11 {
	100% {
		top: 110%;
		left: 86%;
	}
}
.confetti-12 {
	width: 27px;
	height: 2.8px;
	background-color: #00db29;
	top: -10%;
	left: 18%;
	opacity: 0.9550688082;
	transform: rotate(44.7219742164deg);
	-webkit-animation: drop-12 4.4565093664s 0.4923691848s infinite;
	animation: drop-12 4.4565093664s 0.4923691848s infinite;
}

@-webkit-keyframes drop-12 {
	100% {
		top: 110%;
		left: 32%;
	}
}

@keyframes drop-12 {
	100% {
		top: 110%;
		left: 32%;
	}
}
.confetti-13 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 8%;
	opacity: 1.2521086822;
	transform: rotate(241.0203900668deg);
	-webkit-animation: drop-13 4.0703067156s 0.9726492476s infinite;
	animation: drop-13 4.0703067156s 0.9726492476s infinite;
}

@-webkit-keyframes drop-13 {
	100% {
		top: 110%;
		left: 10%;
	}
}

@keyframes drop-13 {
	100% {
		top: 110%;
		left: 10%;
	}
}
.confetti-14 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 30%;
	opacity: 0.5175058426;
	transform: rotate(53.3846061619deg);
	-webkit-animation: drop-14 4.9071556077s 0.7518056695s infinite;
	animation: drop-14 4.9071556077s 0.7518056695s infinite;
}

@-webkit-keyframes drop-14 {
	100% {
		top: 110%;
		left: 45%;
	}
}

@keyframes drop-14 {
	100% {
		top: 110%;
		left: 45%;
	}
}
.confetti-15 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 60%;
	opacity: 0.9164629996;
	transform: rotate(174.2315004531deg);
	-webkit-animation: drop-15 4.872685886s 0.55700769s infinite;
	animation: drop-15 4.872685886s 0.55700769s infinite;
}

@-webkit-keyframes drop-15 {
	100% {
		top: 110%;
		left: 73%;
	}
}

@keyframes drop-15 {
	100% {
		top: 110%;
		left: 73%;
	}
}
.confetti-16 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 58%;
	opacity: 0.9718419277;
	transform: rotate(6.1352397692deg);
	-webkit-animation: drop-16 4.9724324366s 0.3364170392s infinite;
	animation: drop-16 4.9724324366s 0.3364170392s infinite;
}

@-webkit-keyframes drop-16 {
	100% {
		top: 110%;
		left: 63%;
	}
}

@keyframes drop-16 {
	100% {
		top: 110%;
		left: 63%;
	}
}
.confetti-17 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 57%;
	opacity: 0.6586622926;
	transform: rotate(173.2691721798deg);
	-webkit-animation: drop-17 4.9309798172s 0.1657707389s infinite;
	animation: drop-17 4.9309798172s 0.1657707389s infinite;
}

@-webkit-keyframes drop-17 {
	100% {
		top: 110%;
		left: 65%;
	}
}

@keyframes drop-17 {
	100% {
		top: 110%;
		left: 65%;
	}
}
.confetti-18 {
	width: 23px;
	height: 1.2px;
	background-color: #00db29;
	top: -10%;
	left: 16%;
	opacity: 1.3149375001;
	transform: rotate(164.9823526675deg);
	-webkit-animation: drop-18 4.5914511916s 0.4568719374s infinite;
	animation: drop-18 4.5914511916s 0.4568719374s infinite;
}

@-webkit-keyframes drop-18 {
	100% {
		top: 110%;
		left: 23%;
	}
}

@keyframes drop-18 {
	100% {
		top: 110%;
		left: 23%;
	}
}
.confetti-19 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 73%;
	opacity: 1.2164077449;
	transform: rotate(159.1818765789deg);
	-webkit-animation: drop-19 4.6168996523s 0.2781120147s infinite;
	animation: drop-19 4.6168996523s 0.2781120147s infinite;
}

@-webkit-keyframes drop-19 {
	100% {
		top: 110%;
		left: 80%;
	}
}

@keyframes drop-19 {
	100% {
		top: 110%;
		left: 80%;
	}
}
.confetti-20 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 34%;
	opacity: 1.2430367683;
	transform: rotate(75.951147171deg);
	-webkit-animation: drop-20 4.7376134642s 0.1322832904s infinite;
	animation: drop-20 4.7376134642s 0.1322832904s infinite;
}

@-webkit-keyframes drop-20 {
	100% {
		top: 110%;
		left: 36%;
	}
}

@keyframes drop-20 {
	100% {
		top: 110%;
		left: 36%;
	}
}
.confetti-21 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 92%;
	opacity: 0.8068152114;
	transform: rotate(313.8352734543deg);
	-webkit-animation: drop-21 4.8882461129s 0.4024937153s infinite;
	animation: drop-21 4.8882461129s 0.4024937153s infinite;
}

@-webkit-keyframes drop-21 {
	100% {
		top: 110%;
		left: 101%;
	}
}

@keyframes drop-21 {
	100% {
		top: 110%;
		left: 101%;
	}
}
.confetti-22 {
	width: 27px;
	height: 2.8px;
	background-color: #fffc00;
	top: -10%;
	left: 40%;
	opacity: 0.9928258146;
	transform: rotate(176.0949391123deg);
	-webkit-animation: drop-22 4.1914981837s 0.833574128s infinite;
	animation: drop-22 4.1914981837s 0.833574128s infinite;
}

@-webkit-keyframes drop-22 {
	100% {
		top: 110%;
		left: 42%;
	}
}

@keyframes drop-22 {
	100% {
		top: 110%;
		left: 42%;
	}
}
.confetti-23 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 6%;
	opacity: 0.5247768894;
	transform: rotate(153.2546147508deg);
	-webkit-animation: drop-23 4.9434142835s 0.7168538771s infinite;
	animation: drop-23 4.9434142835s 0.7168538771s infinite;
}

@-webkit-keyframes drop-23 {
	100% {
		top: 110%;
		left: 10%;
	}
}

@keyframes drop-23 {
	100% {
		top: 110%;
		left: 10%;
	}
}
.confetti-24 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 9%;
	opacity: 0.6062753025;
	transform: rotate(175.0647295829deg);
	-webkit-animation: drop-24 4.0172955259s 0.6637583757s infinite;
	animation: drop-24 4.0172955259s 0.6637583757s infinite;
}

@-webkit-keyframes drop-24 {
	100% {
		top: 110%;
		left: 14%;
	}
}

@keyframes drop-24 {
	100% {
		top: 110%;
		left: 14%;
	}
}
.confetti-25 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 54%;
	opacity: 1.0168501234;
	transform: rotate(59.7643963807deg);
	-webkit-animation: drop-25 4.1700814393s 0.2495514444s infinite;
	animation: drop-25 4.1700814393s 0.2495514444s infinite;
}

@-webkit-keyframes drop-25 {
	100% {
		top: 110%;
		left: 59%;
	}
}

@keyframes drop-25 {
	100% {
		top: 110%;
		left: 59%;
	}
}
.confetti-26 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 51%;
	opacity: 1.275109187;
	transform: rotate(221.263996069deg);
	-webkit-animation: drop-26 4.4685565442s 0.6251149525s infinite;
	animation: drop-26 4.4685565442s 0.6251149525s infinite;
}

@-webkit-keyframes drop-26 {
	100% {
		top: 110%;
		left: 52%;
	}
}

@keyframes drop-26 {
	100% {
		top: 110%;
		left: 52%;
	}
}
.confetti-27 {
	width: 26px;
	height: 2.4px;
	background-color: #00db29;
	top: -10%;
	left: 29%;
	opacity: 0.5545509691;
	transform: rotate(98.9892479545deg);
	-webkit-animation: drop-27 4.4394061844s 0.4437783097s infinite;
	animation: drop-27 4.4394061844s 0.4437783097s infinite;
}

@-webkit-keyframes drop-27 {
	100% {
		top: 110%;
		left: 33%;
	}
}

@keyframes drop-27 {
	100% {
		top: 110%;
		left: 33%;
	}
}
.confetti-28 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 59%;
	opacity: 0.5783019108;
	transform: rotate(265.481852966deg);
	-webkit-animation: drop-28 4.572171477s 0.8038453923s infinite;
	animation: drop-28 4.572171477s 0.8038453923s infinite;
}

@-webkit-keyframes drop-28 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-28 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-29 {
	width: 21px;
	height: 0.4px;
	background-color: #00db29;
	top: -10%;
	left: 11%;
	opacity: 1.4211918758;
	transform: rotate(337.420772075deg);
	-webkit-animation: drop-29 4.0787596777s 0.6672349354s infinite;
	animation: drop-29 4.0787596777s 0.6672349354s infinite;
}

@-webkit-keyframes drop-29 {
	100% {
		top: 110%;
		left: 20%;
	}
}

@keyframes drop-29 {
	100% {
		top: 110%;
		left: 20%;
	}
}
.confetti-30 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 57%;
	opacity: 1.1976487717;
	transform: rotate(97.8241045214deg);
	-webkit-animation: drop-30 4.923143638s 0.1685134782s infinite;
	animation: drop-30 4.923143638s 0.1685134782s infinite;
}

@-webkit-keyframes drop-30 {
	100% {
		top: 110%;
		left: 68%;
	}
}

@keyframes drop-30 {
	100% {
		top: 110%;
		left: 68%;
	}
}
.confetti-31 {
	width: 25px;
	height: 2px;
	background-color: #00db29;
	top: -10%;
	left: 24%;
	opacity: 0.8927971524;
	transform: rotate(184.9277685923deg);
	-webkit-animation: drop-31 4.1309864253s 0.6248863032s infinite;
	animation: drop-31 4.1309864253s 0.6248863032s infinite;
}

@-webkit-keyframes drop-31 {
	100% {
		top: 110%;
		left: 26%;
	}
}

@keyframes drop-31 {
	100% {
		top: 110%;
		left: 26%;
	}
}
.confetti-32 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 6%;
	opacity: 1.4060123748;
	transform: rotate(28.1956657527deg);
	-webkit-animation: drop-32 4.2236307612s 0.1609051246s infinite;
	animation: drop-32 4.2236307612s 0.1609051246s infinite;
}

@-webkit-keyframes drop-32 {
	100% {
		top: 110%;
		left: 15%;
	}
}

@keyframes drop-32 {
	100% {
		top: 110%;
		left: 15%;
	}
}
.confetti-33 {
	width: 23px;
	height: 1.2px;
	background-color: #fffc00;
	top: -10%;
	left: 35%;
	opacity: 0.6580837127;
	transform: rotate(19.9362154301deg);
	-webkit-animation: drop-33 4.7792329645s 0.932769913s infinite;
	animation: drop-33 4.7792329645s 0.932769913s infinite;
}

@-webkit-keyframes drop-33 {
	100% {
		top: 110%;
		left: 47%;
	}
}

@keyframes drop-33 {
	100% {
		top: 110%;
		left: 47%;
	}
}
.confetti-34 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 75%;
	opacity: 0.8598580191;
	transform: rotate(229.3948477634deg);
	-webkit-animation: drop-34 4.3701671028s 0.8996096978s infinite;
	animation: drop-34 4.3701671028s 0.8996096978s infinite;
}

@-webkit-keyframes drop-34 {
	100% {
		top: 110%;
		left: 77%;
	}
}

@keyframes drop-34 {
	100% {
		top: 110%;
		left: 77%;
	}
}
.confetti-35 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 72%;
	opacity: 0.6681079957;
	transform: rotate(247.4230415999deg);
	-webkit-animation: drop-35 4.2158347013s 0.5713635734s infinite;
	animation: drop-35 4.2158347013s 0.5713635734s infinite;
}

@-webkit-keyframes drop-35 {
	100% {
		top: 110%;
		left: 87%;
	}
}

@keyframes drop-35 {
	100% {
		top: 110%;
		left: 87%;
	}
}
.confetti-36 {
	width: 21px;
	height: 0.4px;
	background-color: #00db29;
	top: -10%;
	left: 86%;
	opacity: 0.5638781491;
	transform: rotate(74.1069713377deg);
	-webkit-animation: drop-36 4.1673286027s 0.9746410734s infinite;
	animation: drop-36 4.1673286027s 0.9746410734s infinite;
}

@-webkit-keyframes drop-36 {
	100% {
		top: 110%;
		left: 97%;
	}
}

@keyframes drop-36 {
	100% {
		top: 110%;
		left: 97%;
	}
}
.confetti-37 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 17%;
	opacity: 1.3302368584;
	transform: rotate(154.2024259947deg);
	-webkit-animation: drop-37 4.8923834997s 0.9496535635s infinite;
	animation: drop-37 4.8923834997s 0.9496535635s infinite;
}

@-webkit-keyframes drop-37 {
	100% {
		top: 110%;
		left: 22%;
	}
}

@keyframes drop-37 {
	100% {
		top: 110%;
		left: 22%;
	}
}
.confetti-38 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 71%;
	opacity: 0.5990925743;
	transform: rotate(334.8614337566deg);
	-webkit-animation: drop-38 4.6524291412s 0.4124858531s infinite;
	animation: drop-38 4.6524291412s 0.4124858531s infinite;
}

@-webkit-keyframes drop-38 {
	100% {
		top: 110%;
		left: 72%;
	}
}

@keyframes drop-38 {
	100% {
		top: 110%;
		left: 72%;
	}
}
.confetti-39 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 39%;
	opacity: 0.7556746851;
	transform: rotate(24.1069159959deg);
	-webkit-animation: drop-39 4.7764991031s 0.9122809462s infinite;
	animation: drop-39 4.7764991031s 0.9122809462s infinite;
}

@-webkit-keyframes drop-39 {
	100% {
		top: 110%;
		left: 48%;
	}
}

@keyframes drop-39 {
	100% {
		top: 110%;
		left: 48%;
	}
}
.confetti-40 {
	width: 21px;
	height: 0.4px;
	background-color: #fffc00;
	top: -10%;
	left: 21%;
	opacity: 1.1906999363;
	transform: rotate(64.1613481634deg);
	-webkit-animation: drop-40 4.267915708s 0.6815263961s infinite;
	animation: drop-40 4.267915708s 0.6815263961s infinite;
}

@-webkit-keyframes drop-40 {
	100% {
		top: 110%;
		left: 30%;
	}
}

@keyframes drop-40 {
	100% {
		top: 110%;
		left: 30%;
	}
}
.confetti-41 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 71%;
	opacity: 1.4792700515;
	transform: rotate(221.0154988373deg);
	-webkit-animation: drop-41 4.2499052698s 0.7690107106s infinite;
	animation: drop-41 4.2499052698s 0.7690107106s infinite;
}

@-webkit-keyframes drop-41 {
	100% {
		top: 110%;
		left: 78%;
	}
}

@keyframes drop-41 {
	100% {
		top: 110%;
		left: 78%;
	}
}
.confetti-42 {
	width: 25px;
	height: 2px;
	background-color: #00db29;
	top: -10%;
	left: 80%;
	opacity: 0.8582956994;
	transform: rotate(215.0101739096deg);
	-webkit-animation: drop-42 4.5161480044s 0.787625889s infinite;
	animation: drop-42 4.5161480044s 0.787625889s infinite;
}

@-webkit-keyframes drop-42 {
	100% {
		top: 110%;
		left: 93%;
	}
}

@keyframes drop-42 {
	100% {
		top: 110%;
		left: 93%;
	}
}
.confetti-43 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 9%;
	opacity: 0.7521562804;
	transform: rotate(262.725103571deg);
	-webkit-animation: drop-43 4.4431760959s 0.6649686553s infinite;
	animation: drop-43 4.4431760959s 0.6649686553s infinite;
}

@-webkit-keyframes drop-43 {
	100% {
		top: 110%;
		left: 19%;
	}
}

@keyframes drop-43 {
	100% {
		top: 110%;
		left: 19%;
	}
}
.confetti-44 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 56%;
	opacity: 0.9297382802;
	transform: rotate(3.6952126929deg);
	-webkit-animation: drop-44 4.256698987s 0.2863538313s infinite;
	animation: drop-44 4.256698987s 0.2863538313s infinite;
}

@-webkit-keyframes drop-44 {
	100% {
		top: 110%;
		left: 68%;
	}
}

@keyframes drop-44 {
	100% {
		top: 110%;
		left: 68%;
	}
}
.confetti-45 {
	width: 25px;
	height: 2px;
	background-color: #00db29;
	top: -10%;
	left: 3%;
	opacity: 0.7597880685;
	transform: rotate(318.0545579909deg);
	-webkit-animation: drop-45 4.4291169408s 0.7614434124s infinite;
	animation: drop-45 4.4291169408s 0.7614434124s infinite;
}

@-webkit-keyframes drop-45 {
	100% {
		top: 110%;
		left: 4%;
	}
}

@keyframes drop-45 {
	100% {
		top: 110%;
		left: 4%;
	}
}
.confetti-46 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 82%;
	opacity: 1.1372944441;
	transform: rotate(192.1645372135deg);
	-webkit-animation: drop-46 4.1473439998s 0.0648243118s infinite;
	animation: drop-46 4.1473439998s 0.0648243118s infinite;
}

@-webkit-keyframes drop-46 {
	100% {
		top: 110%;
		left: 95%;
	}
}

@keyframes drop-46 {
	100% {
		top: 110%;
		left: 95%;
	}
}
.confetti-47 {
	width: 23px;
	height: 1.2px;
	background-color: #fffc00;
	top: -10%;
	left: 22%;
	opacity: 0.8261169595;
	transform: rotate(334.6807677012deg);
	-webkit-animation: drop-47 4.9278129726s 0.6875196325s infinite;
	animation: drop-47 4.9278129726s 0.6875196325s infinite;
}

@-webkit-keyframes drop-47 {
	100% {
		top: 110%;
		left: 24%;
	}
}

@keyframes drop-47 {
	100% {
		top: 110%;
		left: 24%;
	}
}
.confetti-48 {
	width: 27px;
	height: 2.8px;
	background-color: #00db29;
	top: -10%;
	left: 45%;
	opacity: 0.7313703009;
	transform: rotate(290.0932252511deg);
	-webkit-animation: drop-48 4.0824051948s 0.3663887412s infinite;
	animation: drop-48 4.0824051948s 0.3663887412s infinite;
}

@-webkit-keyframes drop-48 {
	100% {
		top: 110%;
		left: 58%;
	}
}

@keyframes drop-48 {
	100% {
		top: 110%;
		left: 58%;
	}
}
.confetti-49 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 80%;
	opacity: 0.9522568908;
	transform: rotate(216.5863802047deg);
	-webkit-animation: drop-49 4.4621257333s 0.2341358474s infinite;
	animation: drop-49 4.4621257333s 0.2341358474s infinite;
}

@-webkit-keyframes drop-49 {
	100% {
		top: 110%;
		left: 83%;
	}
}

@keyframes drop-49 {
	100% {
		top: 110%;
		left: 83%;
	}
}
.confetti-50 {
	width: 21px;
	height: 0.4px;
	background-color: #fffc00;
	top: -10%;
	left: 33%;
	opacity: 1.0164290679;
	transform: rotate(2.1770574124deg);
	-webkit-animation: drop-50 4.6969766877s 0.4607441156s infinite;
	animation: drop-50 4.6969766877s 0.4607441156s infinite;
}

@-webkit-keyframes drop-50 {
	100% {
		top: 110%;
		left: 43%;
	}
}

@keyframes drop-50 {
	100% {
		top: 110%;
		left: 43%;
	}
}
.confetti-51 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 71%;
	opacity: 1.2950903904;
	transform: rotate(80.1426445532deg);
	-webkit-animation: drop-51 4.2439054013s 0.6135408411s infinite;
	animation: drop-51 4.2439054013s 0.6135408411s infinite;
}

@-webkit-keyframes drop-51 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-51 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-52 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 36%;
	opacity: 0.6072599719;
	transform: rotate(83.9273367255deg);
	-webkit-animation: drop-52 4.6264914874s 0.8112605011s infinite;
	animation: drop-52 4.6264914874s 0.8112605011s infinite;
}

@-webkit-keyframes drop-52 {
	100% {
		top: 110%;
		left: 51%;
	}
}

@keyframes drop-52 {
	100% {
		top: 110%;
		left: 51%;
	}
}
.confetti-53 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 87%;
	opacity: 0.5941622816;
	transform: rotate(268.3260183855deg);
	-webkit-animation: drop-53 4.7093178124s 0.2395712807s infinite;
	animation: drop-53 4.7093178124s 0.2395712807s infinite;
}

@-webkit-keyframes drop-53 {
	100% {
		top: 110%;
		left: 96%;
	}
}

@keyframes drop-53 {
	100% {
		top: 110%;
		left: 96%;
	}
}
.confetti-54 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 70%;
	opacity: 1.1717594801;
	transform: rotate(171.2305587768deg);
	-webkit-animation: drop-54 4.612817801s 0.6869056574s infinite;
	animation: drop-54 4.612817801s 0.6869056574s infinite;
}

@-webkit-keyframes drop-54 {
	100% {
		top: 110%;
		left: 81%;
	}
}

@keyframes drop-54 {
	100% {
		top: 110%;
		left: 81%;
	}
}
.confetti-55 {
	width: 23px;
	height: 1.2px;
	background-color: #00db29;
	top: -10%;
	left: 31%;
	opacity: 0.692294841;
	transform: rotate(80.242883247deg);
	-webkit-animation: drop-55 4.3472683806s 0.7317570274s infinite;
	animation: drop-55 4.3472683806s 0.7317570274s infinite;
}

@-webkit-keyframes drop-55 {
	100% {
		top: 110%;
		left: 40%;
	}
}

@keyframes drop-55 {
	100% {
		top: 110%;
		left: 40%;
	}
}
.confetti-56 {
	width: 26px;
	height: 2.4px;
	background-color: #00db29;
	top: -10%;
	left: 18%;
	opacity: 0.7742308532;
	transform: rotate(92.1310755767deg);
	-webkit-animation: drop-56 4.3457150903s 0.0648245856s infinite;
	animation: drop-56 4.3457150903s 0.0648245856s infinite;
}

@-webkit-keyframes drop-56 {
	100% {
		top: 110%;
		left: 29%;
	}
}

@keyframes drop-56 {
	100% {
		top: 110%;
		left: 29%;
	}
}
.confetti-57 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 47%;
	opacity: 1.4949380568;
	transform: rotate(26.177905443deg);
	-webkit-animation: drop-57 4.9940989103s 0.1443910338s infinite;
	animation: drop-57 4.9940989103s 0.1443910338s infinite;
}

@-webkit-keyframes drop-57 {
	100% {
		top: 110%;
		left: 61%;
	}
}

@keyframes drop-57 {
	100% {
		top: 110%;
		left: 61%;
	}
}
.confetti-58 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 84%;
	opacity: 0.6829662989;
	transform: rotate(71.8633881874deg);
	-webkit-animation: drop-58 4.6200919763s 0.2883267274s infinite;
	animation: drop-58 4.6200919763s 0.2883267274s infinite;
}

@-webkit-keyframes drop-58 {
	100% {
		top: 110%;
		left: 98%;
	}
}

@keyframes drop-58 {
	100% {
		top: 110%;
		left: 98%;
	}
}
.confetti-59 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 18%;
	opacity: 0.9942457405;
	transform: rotate(168.3278480564deg);
	-webkit-animation: drop-59 4.7361393819s 0.9594937853s infinite;
	animation: drop-59 4.7361393819s 0.9594937853s infinite;
}

@-webkit-keyframes drop-59 {
	100% {
		top: 110%;
		left: 28%;
	}
}

@keyframes drop-59 {
	100% {
		top: 110%;
		left: 28%;
	}
}
.confetti-60 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 60%;
	opacity: 1.4123349718;
	transform: rotate(15.8219840156deg);
	-webkit-animation: drop-60 4.3773079525s 0.5182912273s infinite;
	animation: drop-60 4.3773079525s 0.5182912273s infinite;
}

@-webkit-keyframes drop-60 {
	100% {
		top: 110%;
		left: 61%;
	}
}

@keyframes drop-60 {
	100% {
		top: 110%;
		left: 61%;
	}
}
.confetti-61 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 61%;
	opacity: 1.0013011175;
	transform: rotate(350.6254762051deg);
	-webkit-animation: drop-61 4.8921077984s 0.2446357177s infinite;
	animation: drop-61 4.8921077984s 0.2446357177s infinite;
}

@-webkit-keyframes drop-61 {
	100% {
		top: 110%;
		left: 63%;
	}
}

@keyframes drop-61 {
	100% {
		top: 110%;
		left: 63%;
	}
}
.confetti-62 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 38%;
	opacity: 0.6239740054;
	transform: rotate(194.1513788553deg);
	-webkit-animation: drop-62 4.1061797806s 0.445350276s infinite;
	animation: drop-62 4.1061797806s 0.445350276s infinite;
}

@-webkit-keyframes drop-62 {
	100% {
		top: 110%;
		left: 45%;
	}
}

@keyframes drop-62 {
	100% {
		top: 110%;
		left: 45%;
	}
}
.confetti-63 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 31%;
	opacity: 1.2303420849;
	transform: rotate(224.0357200028deg);
	-webkit-animation: drop-63 4.1477047207s 0.0733141491s infinite;
	animation: drop-63 4.1477047207s 0.0733141491s infinite;
}

@-webkit-keyframes drop-63 {
	100% {
		top: 110%;
		left: 32%;
	}
}

@keyframes drop-63 {
	100% {
		top: 110%;
		left: 32%;
	}
}
.confetti-64 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 44%;
	opacity: 0.7806503793;
	transform: rotate(187.643086044deg);
	-webkit-animation: drop-64 4.0654877212s 0.1639725847s infinite;
	animation: drop-64 4.0654877212s 0.1639725847s infinite;
}

@-webkit-keyframes drop-64 {
	100% {
		top: 110%;
		left: 54%;
	}
}

@keyframes drop-64 {
	100% {
		top: 110%;
		left: 54%;
	}
}
.confetti-65 {
	width: 27px;
	height: 2.8px;
	background-color: #fffc00;
	top: -10%;
	left: 22%;
	opacity: 0.7170936226;
	transform: rotate(51.7761787486deg);
	-webkit-animation: drop-65 4.9646810171s 0.9099767482s infinite;
	animation: drop-65 4.9646810171s 0.9099767482s infinite;
}

@-webkit-keyframes drop-65 {
	100% {
		top: 110%;
		left: 25%;
	}
}

@keyframes drop-65 {
	100% {
		top: 110%;
		left: 25%;
	}
}
.confetti-66 {
	width: 24px;
	height: 1.6px;
	background-color: #00db29;
	top: -10%;
	left: 83%;
	opacity: 0.5169336291;
	transform: rotate(208.7962141939deg);
	-webkit-animation: drop-66 4.6860762051s 0.9596860178s infinite;
	animation: drop-66 4.6860762051s 0.9596860178s infinite;
}

@-webkit-keyframes drop-66 {
	100% {
		top: 110%;
		left: 85%;
	}
}

@keyframes drop-66 {
	100% {
		top: 110%;
		left: 85%;
	}
}
.confetti-67 {
	width: 24px;
	height: 1.6px;
	background-color: #00db29;
	top: -10%;
	left: 37%;
	opacity: 0.9438502427;
	transform: rotate(177.7730297874deg);
	-webkit-animation: drop-67 4.1997078941s 0.480188038s infinite;
	animation: drop-67 4.1997078941s 0.480188038s infinite;
}

@-webkit-keyframes drop-67 {
	100% {
		top: 110%;
		left: 44%;
	}
}

@keyframes drop-67 {
	100% {
		top: 110%;
		left: 44%;
	}
}
.confetti-68 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 87%;
	opacity: 0.7258336042;
	transform: rotate(213.836934448deg);
	-webkit-animation: drop-68 4.1013383531s 0.0340092821s infinite;
	animation: drop-68 4.1013383531s 0.0340092821s infinite;
}

@-webkit-keyframes drop-68 {
	100% {
		top: 110%;
		left: 93%;
	}
}

@keyframes drop-68 {
	100% {
		top: 110%;
		left: 93%;
	}
}
.confetti-69 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 84%;
	opacity: 1.0808469429;
	transform: rotate(139.1296828994deg);
	-webkit-animation: drop-69 4.6701375683s 0.8390418439s infinite;
	animation: drop-69 4.6701375683s 0.8390418439s infinite;
}

@-webkit-keyframes drop-69 {
	100% {
		top: 110%;
		left: 95%;
	}
}

@keyframes drop-69 {
	100% {
		top: 110%;
		left: 95%;
	}
}
.confetti-70 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 28%;
	opacity: 1.4596742515;
	transform: rotate(266.4211634925deg);
	-webkit-animation: drop-70 4.2799831701s 0.4359384278s infinite;
	animation: drop-70 4.2799831701s 0.4359384278s infinite;
}

@-webkit-keyframes drop-70 {
	100% {
		top: 110%;
		left: 37%;
	}
}

@keyframes drop-70 {
	100% {
		top: 110%;
		left: 37%;
	}
}
.confetti-71 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 25%;
	opacity: 1.4950831513;
	transform: rotate(60.3029502814deg);
	-webkit-animation: drop-71 4.0433862057s 0.6775738655s infinite;
	animation: drop-71 4.0433862057s 0.6775738655s infinite;
}

@-webkit-keyframes drop-71 {
	100% {
		top: 110%;
		left: 28%;
	}
}

@keyframes drop-71 {
	100% {
		top: 110%;
		left: 28%;
	}
}
.confetti-72 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 62%;
	opacity: 0.738966165;
	transform: rotate(165.9820477057deg);
	-webkit-animation: drop-72 4.7765590613s 0.9682734344s infinite;
	animation: drop-72 4.7765590613s 0.9682734344s infinite;
}

@-webkit-keyframes drop-72 {
	100% {
		top: 110%;
		left: 77%;
	}
}

@keyframes drop-72 {
	100% {
		top: 110%;
		left: 77%;
	}
}
.confetti-73 {
	width: 24px;
	height: 1.6px;
	background-color: #00db29;
	top: -10%;
	left: 56%;
	opacity: 0.5967427304;
	transform: rotate(23.008456041deg);
	-webkit-animation: drop-73 4.7428902046s 0.7416193577s infinite;
	animation: drop-73 4.7428902046s 0.7416193577s infinite;
}

@-webkit-keyframes drop-73 {
	100% {
		top: 110%;
		left: 64%;
	}
}

@keyframes drop-73 {
	100% {
		top: 110%;
		left: 64%;
	}
}
.confetti-74 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 66%;
	opacity: 1.2498359801;
	transform: rotate(146.0942761885deg);
	-webkit-animation: drop-74 4.6707717719s 0.8345073582s infinite;
	animation: drop-74 4.6707717719s 0.8345073582s infinite;
}

@-webkit-keyframes drop-74 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-74 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-75 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 31%;
	opacity: 0.7208464786;
	transform: rotate(246.6266200227deg);
	-webkit-animation: drop-75 4.0518098598s 0.3469064212s infinite;
	animation: drop-75 4.0518098598s 0.3469064212s infinite;
}

@-webkit-keyframes drop-75 {
	100% {
		top: 110%;
		left: 46%;
	}
}

@keyframes drop-75 {
	100% {
		top: 110%;
		left: 46%;
	}
}
.confetti-76 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 10%;
	opacity: 0.9108451938;
	transform: rotate(13.3248406975deg);
	-webkit-animation: drop-76 4.930010981s 0.6584167852s infinite;
	animation: drop-76 4.930010981s 0.6584167852s infinite;
}

@-webkit-keyframes drop-76 {
	100% {
		top: 110%;
		left: 18%;
	}
}

@keyframes drop-76 {
	100% {
		top: 110%;
		left: 18%;
	}
}
.confetti-77 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 31%;
	opacity: 0.5160257543;
	transform: rotate(330.4813094678deg);
	-webkit-animation: drop-77 4.6867209975s 0.5466716062s infinite;
	animation: drop-77 4.6867209975s 0.5466716062s infinite;
}

@-webkit-keyframes drop-77 {
	100% {
		top: 110%;
		left: 42%;
	}
}

@keyframes drop-77 {
	100% {
		top: 110%;
		left: 42%;
	}
}
.confetti-78 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 47%;
	opacity: 1.0099488967;
	transform: rotate(48.0061571209deg);
	-webkit-animation: drop-78 4.7868861453s 0.3271500732s infinite;
	animation: drop-78 4.7868861453s 0.3271500732s infinite;
}

@-webkit-keyframes drop-78 {
	100% {
		top: 110%;
		left: 58%;
	}
}

@keyframes drop-78 {
	100% {
		top: 110%;
		left: 58%;
	}
}
.confetti-79 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 10%;
	opacity: 1.0951384683;
	transform: rotate(321.2766366143deg);
	-webkit-animation: drop-79 4.3461030893s 0.2407427436s infinite;
	animation: drop-79 4.3461030893s 0.2407427436s infinite;
}

@-webkit-keyframes drop-79 {
	100% {
		top: 110%;
		left: 15%;
	}
}

@keyframes drop-79 {
	100% {
		top: 110%;
		left: 15%;
	}
}
.confetti-80 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 83%;
	opacity: 0.8271351635;
	transform: rotate(191.4756411478deg);
	-webkit-animation: drop-80 4.8325705597s 0.4451806755s infinite;
	animation: drop-80 4.8325705597s 0.4451806755s infinite;
}

@-webkit-keyframes drop-80 {
	100% {
		top: 110%;
		left: 84%;
	}
}

@keyframes drop-80 {
	100% {
		top: 110%;
		left: 84%;
	}
}
.confetti-81 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 39%;
	opacity: 1.2352561037;
	transform: rotate(149.8153616958deg);
	-webkit-animation: drop-81 4.3134656579s 0.8027213774s infinite;
	animation: drop-81 4.3134656579s 0.8027213774s infinite;
}

@-webkit-keyframes drop-81 {
	100% {
		top: 110%;
		left: 40%;
	}
}

@keyframes drop-81 {
	100% {
		top: 110%;
		left: 40%;
	}
}
.confetti-82 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 42%;
	opacity: 1.4130495181;
	transform: rotate(101.6206162294deg);
	-webkit-animation: drop-82 4.6091392311s 0.7017270774s infinite;
	animation: drop-82 4.6091392311s 0.7017270774s infinite;
}

@-webkit-keyframes drop-82 {
	100% {
		top: 110%;
		left: 52%;
	}
}

@keyframes drop-82 {
	100% {
		top: 110%;
		left: 52%;
	}
}
.confetti-83 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 14%;
	opacity: 0.519929337;
	transform: rotate(238.3292607608deg);
	-webkit-animation: drop-83 4.0694932629s 0.5547548787s infinite;
	animation: drop-83 4.0694932629s 0.5547548787s infinite;
}

@-webkit-keyframes drop-83 {
	100% {
		top: 110%;
		left: 21%;
	}
}

@keyframes drop-83 {
	100% {
		top: 110%;
		left: 21%;
	}
}
.confetti-84 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 70%;
	opacity: 0.6737991882;
	transform: rotate(251.0036111682deg);
	-webkit-animation: drop-84 4.7214613141s 0.9207396365s infinite;
	animation: drop-84 4.7214613141s 0.9207396365s infinite;
}

@-webkit-keyframes drop-84 {
	100% {
		top: 110%;
		left: 75%;
	}
}

@keyframes drop-84 {
	100% {
		top: 110%;
		left: 75%;
	}
}
.confetti-85 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 29%;
	opacity: 0.6847950775;
	transform: rotate(140.7840083367deg);
	-webkit-animation: drop-85 4.0135073998s 0.5839178519s infinite;
	animation: drop-85 4.0135073998s 0.5839178519s infinite;
}

@-webkit-keyframes drop-85 {
	100% {
		top: 110%;
		left: 42%;
	}
}

@keyframes drop-85 {
	100% {
		top: 110%;
		left: 42%;
	}
}
.confetti-86 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 91%;
	opacity: 1.43751152;
	transform: rotate(125.1152117727deg);
	-webkit-animation: drop-86 4.6753959675s 0.6940492716s infinite;
	animation: drop-86 4.6753959675s 0.6940492716s infinite;
}

@-webkit-keyframes drop-86 {
	100% {
		top: 110%;
		left: 105%;
	}
}

@keyframes drop-86 {
	100% {
		top: 110%;
		left: 105%;
	}
}
.confetti-87 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 29%;
	opacity: 0.5631548106;
	transform: rotate(123.9387853819deg);
	-webkit-animation: drop-87 4.3594191168s 0.8851176383s infinite;
	animation: drop-87 4.3594191168s 0.8851176383s infinite;
}

@-webkit-keyframes drop-87 {
	100% {
		top: 110%;
		left: 31%;
	}
}

@keyframes drop-87 {
	100% {
		top: 110%;
		left: 31%;
	}
}
.confetti-88 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 16%;
	opacity: 0.9031344742;
	transform: rotate(290.2053835104deg);
	-webkit-animation: drop-88 4.5761051448s 0.9503532268s infinite;
	animation: drop-88 4.5761051448s 0.9503532268s infinite;
}

@-webkit-keyframes drop-88 {
	100% {
		top: 110%;
		left: 21%;
	}
}

@keyframes drop-88 {
	100% {
		top: 110%;
		left: 21%;
	}
}
.confetti-89 {
	width: 27px;
	height: 2.8px;
	background-color: #00db29;
	top: -10%;
	left: 7%;
	opacity: 0.6078481624;
	transform: rotate(63.897718681deg);
	-webkit-animation: drop-89 4.2046538603s 0.2651666826s infinite;
	animation: drop-89 4.2046538603s 0.2651666826s infinite;
}

@-webkit-keyframes drop-89 {
	100% {
		top: 110%;
		left: 17%;
	}
}

@keyframes drop-89 {
	100% {
		top: 110%;
		left: 17%;
	}
}
.confetti-90 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 12%;
	opacity: 1.0624912903;
	transform: rotate(321.0199474177deg);
	-webkit-animation: drop-90 4.0277354031s 0.6040998073s infinite;
	animation: drop-90 4.0277354031s 0.6040998073s infinite;
}

@-webkit-keyframes drop-90 {
	100% {
		top: 110%;
		left: 23%;
	}
}

@keyframes drop-90 {
	100% {
		top: 110%;
		left: 23%;
	}
}
.confetti-91 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 95%;
	opacity: 1.4562028517;
	transform: rotate(203.2659310717deg);
	-webkit-animation: drop-91 4.3805905605s 0.6973903493s infinite;
	animation: drop-91 4.3805905605s 0.6973903493s infinite;
}

@-webkit-keyframes drop-91 {
	100% {
		top: 110%;
		left: 96%;
	}
}

@keyframes drop-91 {
	100% {
		top: 110%;
		left: 96%;
	}
}
.confetti-92 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 56%;
	opacity: 1.4200145868;
	transform: rotate(234.261358467deg);
	-webkit-animation: drop-92 4.0672960711s 0.2643629257s infinite;
	animation: drop-92 4.0672960711s 0.2643629257s infinite;
}

@-webkit-keyframes drop-92 {
	100% {
		top: 110%;
		left: 62%;
	}
}

@keyframes drop-92 {
	100% {
		top: 110%;
		left: 62%;
	}
}
.confetti-93 {
	width: 26px;
	height: 2.4px;
	background-color: #00db29;
	top: -10%;
	left: 84%;
	opacity: 0.8649424625;
	transform: rotate(269.6249268795deg);
	-webkit-animation: drop-93 4.039357413s 0.8602829471s infinite;
	animation: drop-93 4.039357413s 0.8602829471s infinite;
}

@-webkit-keyframes drop-93 {
	100% {
		top: 110%;
		left: 86%;
	}
}

@keyframes drop-93 {
	100% {
		top: 110%;
		left: 86%;
	}
}
.confetti-94 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 17%;
	opacity: 0.5003757984;
	transform: rotate(39.9966106429deg);
	-webkit-animation: drop-94 4.4903852203s 0.8852705375s infinite;
	animation: drop-94 4.4903852203s 0.8852705375s infinite;
}

@-webkit-keyframes drop-94 {
	100% {
		top: 110%;
		left: 24%;
	}
}

@keyframes drop-94 {
	100% {
		top: 110%;
		left: 24%;
	}
}
.confetti-95 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 23%;
	opacity: 1.0865532632;
	transform: rotate(28.7190342319deg);
	-webkit-animation: drop-95 4.8485542962s 0.9091720758s infinite;
	animation: drop-95 4.8485542962s 0.9091720758s infinite;
}

@-webkit-keyframes drop-95 {
	100% {
		top: 110%;
		left: 25%;
	}
}

@keyframes drop-95 {
	100% {
		top: 110%;
		left: 25%;
	}
}
.confetti-96 {
	width: 21px;
	height: 0.4px;
	background-color: #fffc00;
	top: -10%;
	left: 86%;
	opacity: 1.4208798668;
	transform: rotate(216.6026767931deg);
	-webkit-animation: drop-96 4.5548876523s 0.1603961275s infinite;
	animation: drop-96 4.5548876523s 0.1603961275s infinite;
}

@-webkit-keyframes drop-96 {
	100% {
		top: 110%;
		left: 94%;
	}
}

@keyframes drop-96 {
	100% {
		top: 110%;
		left: 94%;
	}
}
.confetti-97 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 3%;
	opacity: 1.3988844891;
	transform: rotate(192.4948635694deg);
	-webkit-animation: drop-97 4.342399147s 0.1835404568s infinite;
	animation: drop-97 4.342399147s 0.1835404568s infinite;
}

@-webkit-keyframes drop-97 {
	100% {
		top: 110%;
		left: 5%;
	}
}

@keyframes drop-97 {
	100% {
		top: 110%;
		left: 5%;
	}
}
.confetti-98 {
	width: 25px;
	height: 2px;
	background-color: #00db29;
	top: -10%;
	left: 17%;
	opacity: 1.1805931416;
	transform: rotate(243.9922930202deg);
	-webkit-animation: drop-98 4.8870761696s 0.5748126347s infinite;
	animation: drop-98 4.8870761696s 0.5748126347s infinite;
}

@-webkit-keyframes drop-98 {
	100% {
		top: 110%;
		left: 30%;
	}
}

@keyframes drop-98 {
	100% {
		top: 110%;
		left: 30%;
	}
}
.confetti-99 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 77%;
	opacity: 0.9624244205;
	transform: rotate(223.8284097392deg);
	-webkit-animation: drop-99 4.7836985746s 0.1384481736s infinite;
	animation: drop-99 4.7836985746s 0.1384481736s infinite;
}

@-webkit-keyframes drop-99 {
	100% {
		top: 110%;
		left: 86%;
	}
}

@keyframes drop-99 {
	100% {
		top: 110%;
		left: 86%;
	}
}
.confetti-100 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 81%;
	opacity: 0.8905624359;
	transform: rotate(140.5612454763deg);
	-webkit-animation: drop-100 4.9246625811s 0.531291965s infinite;
	animation: drop-100 4.9246625811s 0.531291965s infinite;
}

@-webkit-keyframes drop-100 {
	100% {
		top: 110%;
		left: 91%;
	}
}

@keyframes drop-100 {
	100% {
		top: 110%;
		left: 91%;
	}
}
.confetti-101 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 61%;
	opacity: 1.2004565433;
	transform: rotate(47.1836741034deg);
	-webkit-animation: drop-101 4.471984257s 0.1409552681s infinite;
	animation: drop-101 4.471984257s 0.1409552681s infinite;
}

@-webkit-keyframes drop-101 {
	100% {
		top: 110%;
		left: 75%;
	}
}

@keyframes drop-101 {
	100% {
		top: 110%;
		left: 75%;
	}
}
.confetti-102 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 40%;
	opacity: 0.6985885335;
	transform: rotate(7.5032864164deg);
	-webkit-animation: drop-102 4.0257654419s 0.1316604775s infinite;
	animation: drop-102 4.0257654419s 0.1316604775s infinite;
}

@-webkit-keyframes drop-102 {
	100% {
		top: 110%;
		left: 48%;
	}
}

@keyframes drop-102 {
	100% {
		top: 110%;
		left: 48%;
	}
}
.confetti-103 {
	width: 27px;
	height: 2.8px;
	background-color: #fffc00;
	top: -10%;
	left: 72%;
	opacity: 0.8085074334;
	transform: rotate(2.88167048deg);
	-webkit-animation: drop-103 4.5595487076s 0.3522642661s infinite;
	animation: drop-103 4.5595487076s 0.3522642661s infinite;
}

@-webkit-keyframes drop-103 {
	100% {
		top: 110%;
		left: 85%;
	}
}

@keyframes drop-103 {
	100% {
		top: 110%;
		left: 85%;
	}
}
.confetti-104 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 64%;
	opacity: 1.0390952052;
	transform: rotate(10.6053049315deg);
	-webkit-animation: drop-104 4.5999490116s 0.2879185489s infinite;
	animation: drop-104 4.5999490116s 0.2879185489s infinite;
}

@-webkit-keyframes drop-104 {
	100% {
		top: 110%;
		left: 72%;
	}
}

@keyframes drop-104 {
	100% {
		top: 110%;
		left: 72%;
	}
}
.confetti-105 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 17%;
	opacity: 1.0705218799;
	transform: rotate(78.9387235002deg);
	-webkit-animation: drop-105 4.6654828789s 0.1764605936s infinite;
	animation: drop-105 4.6654828789s 0.1764605936s infinite;
}

@-webkit-keyframes drop-105 {
	100% {
		top: 110%;
		left: 30%;
	}
}

@keyframes drop-105 {
	100% {
		top: 110%;
		left: 30%;
	}
}
.confetti-106 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 43%;
	opacity: 1.2751786433;
	transform: rotate(270.8126409573deg);
	-webkit-animation: drop-106 4.6409884258s 0.9463115441s infinite;
	animation: drop-106 4.6409884258s 0.9463115441s infinite;
}

@-webkit-keyframes drop-106 {
	100% {
		top: 110%;
		left: 48%;
	}
}

@keyframes drop-106 {
	100% {
		top: 110%;
		left: 48%;
	}
}
.confetti-107 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 5%;
	opacity: 0.6713632282;
	transform: rotate(185.6656403519deg);
	-webkit-animation: drop-107 4.8176744573s 0.5273996379s infinite;
	animation: drop-107 4.8176744573s 0.5273996379s infinite;
}

@-webkit-keyframes drop-107 {
	100% {
		top: 110%;
		left: 12%;
	}
}

@keyframes drop-107 {
	100% {
		top: 110%;
		left: 12%;
	}
}
.confetti-108 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 22%;
	opacity: 1.3633747153;
	transform: rotate(219.131582986deg);
	-webkit-animation: drop-108 4.3240347002s 0.3462639533s infinite;
	animation: drop-108 4.3240347002s 0.3462639533s infinite;
}

@-webkit-keyframes drop-108 {
	100% {
		top: 110%;
		left: 32%;
	}
}

@keyframes drop-108 {
	100% {
		top: 110%;
		left: 32%;
	}
}
.confetti-109 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 11%;
	opacity: 1.258931523;
	transform: rotate(228.0025453667deg);
	-webkit-animation: drop-109 4.3967162398s 0.3008027722s infinite;
	animation: drop-109 4.3967162398s 0.3008027722s infinite;
}

@-webkit-keyframes drop-109 {
	100% {
		top: 110%;
		left: 14%;
	}
}

@keyframes drop-109 {
	100% {
		top: 110%;
		left: 14%;
	}
}
.confetti-110 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 20%;
	opacity: 0.6936488024;
	transform: rotate(70.9113918966deg);
	-webkit-animation: drop-110 4.5834981904s 0.7047493548s infinite;
	animation: drop-110 4.5834981904s 0.7047493548s infinite;
}

@-webkit-keyframes drop-110 {
	100% {
		top: 110%;
		left: 22%;
	}
}

@keyframes drop-110 {
	100% {
		top: 110%;
		left: 22%;
	}
}
.confetti-111 {
	width: 22px;
	height: 0.8px;
	background-color: #00db29;
	top: -10%;
	left: 94%;
	opacity: 0.6369860467;
	transform: rotate(40.6913749175deg);
	-webkit-animation: drop-111 4.7859921412s 0.828273774s infinite;
	animation: drop-111 4.7859921412s 0.828273774s infinite;
}

@-webkit-keyframes drop-111 {
	100% {
		top: 110%;
		left: 102%;
	}
}

@keyframes drop-111 {
	100% {
		top: 110%;
		left: 102%;
	}
}
.confetti-112 {
	width: 28px;
	height: 3.2px;
	background-color: #fffc00;
	top: -10%;
	left: 44%;
	opacity: 0.8957092715;
	transform: rotate(226.9523335333deg);
	-webkit-animation: drop-112 4.9238923977s 0.1315759925s infinite;
	animation: drop-112 4.9238923977s 0.1315759925s infinite;
}

@-webkit-keyframes drop-112 {
	100% {
		top: 110%;
		left: 57%;
	}
}

@keyframes drop-112 {
	100% {
		top: 110%;
		left: 57%;
	}
}
.confetti-113 {
	width: 21px;
	height: 0.4px;
	background-color: #fffc00;
	top: -10%;
	left: 97%;
	opacity: 1.2718667915;
	transform: rotate(301.2308950231deg);
	-webkit-animation: drop-113 4.8386994326s 0.5839620335s infinite;
	animation: drop-113 4.8386994326s 0.5839620335s infinite;
}

@-webkit-keyframes drop-113 {
	100% {
		top: 110%;
		left: 111%;
	}
}

@keyframes drop-113 {
	100% {
		top: 110%;
		left: 111%;
	}
}
.confetti-114 {
	width: 27px;
	height: 2.8px;
	background-color: #00db29;
	top: -10%;
	left: 70%;
	opacity: 1.4552041289;
	transform: rotate(178.2413012854deg);
	-webkit-animation: drop-114 4.4449852686s 0.7526672855s infinite;
	animation: drop-114 4.4449852686s 0.7526672855s infinite;
}

@-webkit-keyframes drop-114 {
	100% {
		top: 110%;
		left: 73%;
	}
}

@keyframes drop-114 {
	100% {
		top: 110%;
		left: 73%;
	}
}
.confetti-115 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 16%;
	opacity: 0.9324314915;
	transform: rotate(47.7164985987deg);
	-webkit-animation: drop-115 4.5923088607s 0.4526111808s infinite;
	animation: drop-115 4.5923088607s 0.4526111808s infinite;
}

@-webkit-keyframes drop-115 {
	100% {
		top: 110%;
		left: 20%;
	}
}

@keyframes drop-115 {
	100% {
		top: 110%;
		left: 20%;
	}
}
.confetti-116 {
	width: 21px;
	height: 0.4px;
	background-color: #00db29;
	top: -10%;
	left: 19%;
	opacity: 1.2216958628;
	transform: rotate(150.776384349deg);
	-webkit-animation: drop-116 4.0831176968s 0.257018715s infinite;
	animation: drop-116 4.0831176968s 0.257018715s infinite;
}

@-webkit-keyframes drop-116 {
	100% {
		top: 110%;
		left: 23%;
	}
}

@keyframes drop-116 {
	100% {
		top: 110%;
		left: 23%;
	}
}
.confetti-117 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 66%;
	opacity: 1.056589272;
	transform: rotate(351.5222592823deg);
	-webkit-animation: drop-117 4.2268907193s 0.7509484977s infinite;
	animation: drop-117 4.2268907193s 0.7509484977s infinite;
}

@-webkit-keyframes drop-117 {
	100% {
		top: 110%;
		left: 78%;
	}
}

@keyframes drop-117 {
	100% {
		top: 110%;
		left: 78%;
	}
}
.confetti-118 {
	width: 27px;
	height: 2.8px;
	background-color: #fffc00;
	top: -10%;
	left: 93%;
	opacity: 1.3053157348;
	transform: rotate(124.3267396127deg);
	-webkit-animation: drop-118 4.2586722171s 0.9381883044s infinite;
	animation: drop-118 4.2586722171s 0.9381883044s infinite;
}

@-webkit-keyframes drop-118 {
	100% {
		top: 110%;
		left: 95%;
	}
}

@keyframes drop-118 {
	100% {
		top: 110%;
		left: 95%;
	}
}
.confetti-119 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 84%;
	opacity: 1.4734000358;
	transform: rotate(248.8153672212deg);
	-webkit-animation: drop-119 4.9590252323s 0.740471479s infinite;
	animation: drop-119 4.9590252323s 0.740471479s infinite;
}

@-webkit-keyframes drop-119 {
	100% {
		top: 110%;
		left: 86%;
	}
}

@keyframes drop-119 {
	100% {
		top: 110%;
		left: 86%;
	}
}
.confetti-120 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 3%;
	opacity: 1.1844617148;
	transform: rotate(327.1675423423deg);
	-webkit-animation: drop-120 4.8975684207s 0.3655699869s infinite;
	animation: drop-120 4.8975684207s 0.3655699869s infinite;
}

@-webkit-keyframes drop-120 {
	100% {
		top: 110%;
		left: 7%;
	}
}

@keyframes drop-120 {
	100% {
		top: 110%;
		left: 7%;
	}
}
.confetti-121 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 48%;
	opacity: 0.605036761;
	transform: rotate(338.8530939993deg);
	-webkit-animation: drop-121 4.3087817007s 0.6110053228s infinite;
	animation: drop-121 4.3087817007s 0.6110053228s infinite;
}

@-webkit-keyframes drop-121 {
	100% {
		top: 110%;
		left: 53%;
	}
}

@keyframes drop-121 {
	100% {
		top: 110%;
		left: 53%;
	}
}
.confetti-122 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 17%;
	opacity: 0.9843194904;
	transform: rotate(339.6172400611deg);
	-webkit-animation: drop-122 4.5787968777s 0.8109520274s infinite;
	animation: drop-122 4.5787968777s 0.8109520274s infinite;
}

@-webkit-keyframes drop-122 {
	100% {
		top: 110%;
		left: 29%;
	}
}

@keyframes drop-122 {
	100% {
		top: 110%;
		left: 29%;
	}
}
.confetti-123 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 32%;
	opacity: 0.7486764527;
	transform: rotate(280.8279686783deg);
	-webkit-animation: drop-123 4.6705532464s 0.0893630362s infinite;
	animation: drop-123 4.6705532464s 0.0893630362s infinite;
}

@-webkit-keyframes drop-123 {
	100% {
		top: 110%;
		left: 39%;
	}
}

@keyframes drop-123 {
	100% {
		top: 110%;
		left: 39%;
	}
}
.confetti-124 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 89%;
	opacity: 0.8205574662;
	transform: rotate(35.3277761591deg);
	-webkit-animation: drop-124 4.1405398142s 0.9812954375s infinite;
	animation: drop-124 4.1405398142s 0.9812954375s infinite;
}

@-webkit-keyframes drop-124 {
	100% {
		top: 110%;
		left: 97%;
	}
}

@keyframes drop-124 {
	100% {
		top: 110%;
		left: 97%;
	}
}
.confetti-125 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 94%;
	opacity: 0.5211195022;
	transform: rotate(9.8659203871deg);
	-webkit-animation: drop-125 4.7864452355s 0.3883008571s infinite;
	animation: drop-125 4.7864452355s 0.3883008571s infinite;
}

@-webkit-keyframes drop-125 {
	100% {
		top: 110%;
		left: 104%;
	}
}

@keyframes drop-125 {
	100% {
		top: 110%;
		left: 104%;
	}
}
.confetti-126 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 32%;
	opacity: 1.2620544296;
	transform: rotate(263.2561565946deg);
	-webkit-animation: drop-126 4.309013154s 0.6422279255s infinite;
	animation: drop-126 4.309013154s 0.6422279255s infinite;
}

@-webkit-keyframes drop-126 {
	100% {
		top: 110%;
		left: 47%;
	}
}

@keyframes drop-126 {
	100% {
		top: 110%;
		left: 47%;
	}
}
.confetti-127 {
	width: 23px;
	height: 1.2px;
	background-color: #f5001e;
	top: -10%;
	left: 20%;
	opacity: 1.4375427626;
	transform: rotate(323.1306561476deg);
	-webkit-animation: drop-127 4.1652175484s 0.8828838084s infinite;
	animation: drop-127 4.1652175484s 0.8828838084s infinite;
}

@-webkit-keyframes drop-127 {
	100% {
		top: 110%;
		left: 27%;
	}
}

@keyframes drop-127 {
	100% {
		top: 110%;
		left: 27%;
	}
}
.confetti-128 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 7%;
	opacity: 1.3333734215;
	transform: rotate(168.8597014655deg);
	-webkit-animation: drop-128 4.8457178426s 0.056088529s infinite;
	animation: drop-128 4.8457178426s 0.056088529s infinite;
}

@-webkit-keyframes drop-128 {
	100% {
		top: 110%;
		left: 16%;
	}
}

@keyframes drop-128 {
	100% {
		top: 110%;
		left: 16%;
	}
}
.confetti-129 {
	width: 24px;
	height: 1.6px;
	background-color: #fffc00;
	top: -10%;
	left: 46%;
	opacity: 0.5540996978;
	transform: rotate(262.4097282983deg);
	-webkit-animation: drop-129 4.1038695191s 0.6346763384s infinite;
	animation: drop-129 4.1038695191s 0.6346763384s infinite;
}

@-webkit-keyframes drop-129 {
	100% {
		top: 110%;
		left: 56%;
	}
}

@keyframes drop-129 {
	100% {
		top: 110%;
		left: 56%;
	}
}
.confetti-130 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 50%;
	opacity: 0.8319786146;
	transform: rotate(3.7475618096deg);
	-webkit-animation: drop-130 4.7234215595s 0.2087358902s infinite;
	animation: drop-130 4.7234215595s 0.2087358902s infinite;
}

@-webkit-keyframes drop-130 {
	100% {
		top: 110%;
		left: 59%;
	}
}

@keyframes drop-130 {
	100% {
		top: 110%;
		left: 59%;
	}
}
.confetti-131 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 90%;
	opacity: 1.1184265197;
	transform: rotate(215.6204910452deg);
	-webkit-animation: drop-131 4.9060431574s 0.2208291619s infinite;
	animation: drop-131 4.9060431574s 0.2208291619s infinite;
}

@-webkit-keyframes drop-131 {
	100% {
		top: 110%;
		left: 103%;
	}
}

@keyframes drop-131 {
	100% {
		top: 110%;
		left: 103%;
	}
}
.confetti-132 {
	width: 26px;
	height: 2.4px;
	background-color: #00db29;
	top: -10%;
	left: 69%;
	opacity: 0.908873072;
	transform: rotate(136.3924298714deg);
	-webkit-animation: drop-132 4.9483487581s 0.4698145289s infinite;
	animation: drop-132 4.9483487581s 0.4698145289s infinite;
}

@-webkit-keyframes drop-132 {
	100% {
		top: 110%;
		left: 74%;
	}
}

@keyframes drop-132 {
	100% {
		top: 110%;
		left: 74%;
	}
}
.confetti-133 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 37%;
	opacity: 0.9592448003;
	transform: rotate(267.30448102deg);
	-webkit-animation: drop-133 4.6001537229s 0.0333872061s infinite;
	animation: drop-133 4.6001537229s 0.0333872061s infinite;
}

@-webkit-keyframes drop-133 {
	100% {
		top: 110%;
		left: 51%;
	}
}

@keyframes drop-133 {
	100% {
		top: 110%;
		left: 51%;
	}
}
.confetti-134 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 34%;
	opacity: 1.4862124346;
	transform: rotate(103.995807629deg);
	-webkit-animation: drop-134 4.8204507118s 0.0661329679s infinite;
	animation: drop-134 4.8204507118s 0.0661329679s infinite;
}

@-webkit-keyframes drop-134 {
	100% {
		top: 110%;
		left: 45%;
	}
}

@keyframes drop-134 {
	100% {
		top: 110%;
		left: 45%;
	}
}
.confetti-135 {
	width: 21px;
	height: 0.4px;
	background-color: #00db29;
	top: -10%;
	left: 55%;
	opacity: 1.2062316713;
	transform: rotate(87.5081408883deg);
	-webkit-animation: drop-135 4.0680297146s 0.8153289556s infinite;
	animation: drop-135 4.0680297146s 0.8153289556s infinite;
}

@-webkit-keyframes drop-135 {
	100% {
		top: 110%;
		left: 56%;
	}
}

@keyframes drop-135 {
	100% {
		top: 110%;
		left: 56%;
	}
}
.confetti-136 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 51%;
	opacity: 0.5335467407;
	transform: rotate(220.2013616615deg);
	-webkit-animation: drop-136 4.5015162324s 0.8349476798s infinite;
	animation: drop-136 4.5015162324s 0.8349476798s infinite;
}

@-webkit-keyframes drop-136 {
	100% {
		top: 110%;
		left: 57%;
	}
}

@keyframes drop-136 {
	100% {
		top: 110%;
		left: 57%;
	}
}
.confetti-137 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 11%;
	opacity: 0.6088647049;
	transform: rotate(288.0966278697deg);
	-webkit-animation: drop-137 4.8493712016s 0.6497472171s infinite;
	animation: drop-137 4.8493712016s 0.6497472171s infinite;
}

@-webkit-keyframes drop-137 {
	100% {
		top: 110%;
		left: 12%;
	}
}

@keyframes drop-137 {
	100% {
		top: 110%;
		left: 12%;
	}
}
.confetti-138 {
	width: 28px;
	height: 3.2px;
	background-color: #f5001e;
	top: -10%;
	left: 89%;
	opacity: 0.769124488;
	transform: rotate(66.7968876621deg);
	-webkit-animation: drop-138 4.3306503593s 0.8001776598s infinite;
	animation: drop-138 4.3306503593s 0.8001776598s infinite;
}

@-webkit-keyframes drop-138 {
	100% {
		top: 110%;
		left: 102%;
	}
}

@keyframes drop-138 {
	100% {
		top: 110%;
		left: 102%;
	}
}
.confetti-139 {
	width: 22px;
	height: 0.8px;
	background-color: #fffc00;
	top: -10%;
	left: 46%;
	opacity: 1.1809982722;
	transform: rotate(296.9369697888deg);
	-webkit-animation: drop-139 4.7387674751s 0.7235655594s infinite;
	animation: drop-139 4.7387674751s 0.7235655594s infinite;
}

@-webkit-keyframes drop-139 {
	100% {
		top: 110%;
		left: 56%;
	}
}

@keyframes drop-139 {
	100% {
		top: 110%;
		left: 56%;
	}
}
.confetti-140 {
	width: 27px;
	height: 2.8px;
	background-color: #fffc00;
	top: -10%;
	left: 66%;
	opacity: 1.0810470103;
	transform: rotate(152.8197639952deg);
	-webkit-animation: drop-140 4.6341420832s 0.2572979402s infinite;
	animation: drop-140 4.6341420832s 0.2572979402s infinite;
}

@-webkit-keyframes drop-140 {
	100% {
		top: 110%;
		left: 80%;
	}
}

@keyframes drop-140 {
	100% {
		top: 110%;
		left: 80%;
	}
}
.confetti-141 {
	width: 25px;
	height: 2px;
	background-color: #f5001e;
	top: -10%;
	left: 47%;
	opacity: 1.2049758087;
	transform: rotate(88.9399281492deg);
	-webkit-animation: drop-141 4.5432492496s 0.5615526717s infinite;
	animation: drop-141 4.5432492496s 0.5615526717s infinite;
}

@-webkit-keyframes drop-141 {
	100% {
		top: 110%;
		left: 53%;
	}
}

@keyframes drop-141 {
	100% {
		top: 110%;
		left: 53%;
	}
}
.confetti-142 {
	width: 25px;
	height: 2px;
	background-color: #fffc00;
	top: -10%;
	left: 57%;
	opacity: 0.6468772283;
	transform: rotate(105.0383602134deg);
	-webkit-animation: drop-142 4.0870354741s 0.603175971s infinite;
	animation: drop-142 4.0870354741s 0.603175971s infinite;
}

@-webkit-keyframes drop-142 {
	100% {
		top: 110%;
		left: 65%;
	}
}

@keyframes drop-142 {
	100% {
		top: 110%;
		left: 65%;
	}
}
.confetti-143 {
	width: 25px;
	height: 2px;
	background-color: #00db29;
	top: -10%;
	left: 43%;
	opacity: 0.8909664732;
	transform: rotate(333.5639014949deg);
	-webkit-animation: drop-143 4.556126598s 0.0109919765s infinite;
	animation: drop-143 4.556126598s 0.0109919765s infinite;
}

@-webkit-keyframes drop-143 {
	100% {
		top: 110%;
		left: 57%;
	}
}

@keyframes drop-143 {
	100% {
		top: 110%;
		left: 57%;
	}
}
.confetti-144 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 37%;
	opacity: 1.3661025314;
	transform: rotate(326.1732323444deg);
	-webkit-animation: drop-144 4.8831440026s 0.2492069277s infinite;
	animation: drop-144 4.8831440026s 0.2492069277s infinite;
}

@-webkit-keyframes drop-144 {
	100% {
		top: 110%;
		left: 46%;
	}
}

@keyframes drop-144 {
	100% {
		top: 110%;
		left: 46%;
	}
}
.confetti-145 {
	width: 27px;
	height: 2.8px;
	background-color: #f5001e;
	top: -10%;
	left: 73%;
	opacity: 0.6452162062;
	transform: rotate(307.5175257377deg);
	-webkit-animation: drop-145 4.2503529487s 0.4892994054s infinite;
	animation: drop-145 4.2503529487s 0.4892994054s infinite;
}

@-webkit-keyframes drop-145 {
	100% {
		top: 110%;
		left: 79%;
	}
}

@keyframes drop-145 {
	100% {
		top: 110%;
		left: 79%;
	}
}
.confetti-146 {
	width: 22px;
	height: 0.8px;
	background-color: #f5001e;
	top: -10%;
	left: 30%;
	opacity: 0.7113767163;
	transform: rotate(49.6895823446deg);
	-webkit-animation: drop-146 4.5799564725s 0.9619239873s infinite;
	animation: drop-146 4.5799564725s 0.9619239873s infinite;
}

@-webkit-keyframes drop-146 {
	100% {
		top: 110%;
		left: 43%;
	}
}

@keyframes drop-146 {
	100% {
		top: 110%;
		left: 43%;
	}
}
.confetti-147 {
	width: 21px;
	height: 0.4px;
	background-color: #f5001e;
	top: -10%;
	left: 43%;
	opacity: 1.3803830483;
	transform: rotate(51.1539109864deg);
	-webkit-animation: drop-147 4.3239099495s 0.6263224887s infinite;
	animation: drop-147 4.3239099495s 0.6263224887s infinite;
}

@-webkit-keyframes drop-147 {
	100% {
		top: 110%;
		left: 49%;
	}
}

@keyframes drop-147 {
	100% {
		top: 110%;
		left: 49%;
	}
}
.confetti-148 {
	width: 23px;
	height: 1.2px;
	background-color: #fffc00;
	top: -10%;
	left: 91%;
	opacity: 0.7844065469;
	transform: rotate(92.7667757726deg);
	-webkit-animation: drop-148 4.5215707481s 0.4522856137s infinite;
	animation: drop-148 4.5215707481s 0.4522856137s infinite;
}

@-webkit-keyframes drop-148 {
	100% {
		top: 110%;
		left: 106%;
	}
}

@keyframes drop-148 {
	100% {
		top: 110%;
		left: 106%;
	}
}
.confetti-149 {
	width: 26px;
	height: 2.4px;
	background-color: #f5001e;
	top: -10%;
	left: 96%;
	opacity: 0.7037788738;
	transform: rotate(39.7387707854deg);
	-webkit-animation: drop-149 4.4729712389s 0.9089348959s infinite;
	animation: drop-149 4.4729712389s 0.9089348959s infinite;
}

@-webkit-keyframes drop-149 {
	100% {
		top: 110%;
		left: 111%;
	}
}

@keyframes drop-149 {
	100% {
		top: 110%;
		left: 111%;
	}
}
.confetti-150 {
	width: 28px;
	height: 3.2px;
	background-color: #00db29;
	top: -10%;
	left: 97%;
	opacity: 0.5193295449;
	transform: rotate(130.6787909034deg);
	-webkit-animation: drop-150 4.4335235613s 0.7307090206s infinite;
	animation: drop-150 4.4335235613s 0.7307090206s infinite;
}

@-webkit-keyframes drop-150 {
	100% {
		top: 110%;
		left: 104%;
	}
}

@keyframes drop-150 {
	100% {
		top: 110%;
		left: 104%;
	}
}

/* HTML SCSS JSResult Skip Results Iframe
body {
  margin: 0;
  overflow: hidden;
}

.wrapper {
  position: relative;
  min-height: 100vh;
}

[class|="confetti"] {
  position: absolute;
}

$colors: (#f5001e, #fffc00, #00db29);

@for $i from 0 through 150 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
} */
